<template>
  <a-table
    :dataSource="tableData"
    :columns="column"
    :bordered="false"
    :scroll="scroll"
    :loading="tableLoading"
    :pagination="false"
    :row-selection="isSelections ? rowSelection : undefined"
    :custom-row="isSelections ? customRow : undefined"
  >
    <template v-slot:headerCell="{ column }">
      <slot name="headerCell" :column="column"> </slot>
    </template>
    <template
      v-for="(item, index) in slots"
      :key="index"
      #[item.slots.customRender]="row"
    >
      <div v-if="item.slots">
        <slot :name="item.slots.customRender" :scope="row"></slot>
      </div>
    </template>
    <template v-slot:bodyCell="{ column, record, index }">
      <slot
        v-if="!column.isMinute"
        :name="column.key"
        :record="record"
        :index="index"
      >
      </slot>
      <slot v-else> {{ column.symbol }}{{ record[column.key] / 100 }} </slot>
    </template>
  </a-table>
</template>
<script lang="ts">
import { ref, watch, computed, onMounted, reactive } from "vue";
import type { TableProps, TableColumnType } from "ant-design-vue";
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    scroll: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSelection:{
      type: Boolean,
      default: false,
    }
  },
  setup(props: any, ctx: any) {
    const column = ref(props.columns);
    const tableData = ref(props.dataSource);
    const tableLoading = ref(props.loading);
    const isSelections = ref(false);

    const state = reactive({
      selectedRowKeys: <any>[],
    });
    watch(
      () => props.isSelection,
      (val) => {
        isSelections.value = val;
      },{immediate:true}
    );
    watch(
      () => props.columns,
      (val) => {
        column.value = val;
      }
    );
    watch(
      () => props.dataSource,
      (val) => {
        tableData.value = val;
      }
    );
    watch(
      () => props.loading,
      (val) => {
        tableLoading.value = val;
      }
    );
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        hideDefaultSelections: true,
        hideSelectAll: true,
        type: "radio",
        onChange: (selectedRowKeys: any[], selectedRows: any) => {
          state.selectedRowKeys = selectedRowKeys;
          ctx.emit("selectionChange", selectedRows);
        },
        getCheckboxProps: (record: any) => ({}),
      };
    });

    const selectRow = (record: any) => {
      const selectedRowKeys: any[] = [...state.selectedRowKeys];
      // if (selectedRowKeys.indexOf(record.key) >= 0) {
      //   selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
      // } else {
        selectedRowKeys.push(record.key);
      // }
      state.selectedRowKeys = selectedRowKeys;
    };
    const customRow = (record: any) => {
      return {
        onClick: () => {
          selectRow(record);
          ctx.emit("selectionChange", record);
        },
      };
    };
    onMounted(() => {
      props.columns.filter((vals: any) => {
        return vals.slots;
      });
    });
    const slots = computed(() => {
      return props.columns.filter((val: any) => {
        return val.slots;
      });
    });

    return {
      column,
      tableData,
      slots,
      tableLoading,
      isSelections,
      rowSelection,
      customRow,
    };
  },
};
</script>

<style></style>
