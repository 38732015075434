<template>
  <div class="searchFrom">
    <a-form
      name="customized_form_controls"
      layout="inline"
      :model="searchFormState"
      ref="searchFormRef"
    >
      <!-- <a-form-item name="price" label="时间">
            <a-range-picker
              v-model:value="searchFormState.time"
              :valueFormat="dateFormat"
              @change="selectChange"
            />
          </a-form-item> -->
      <a-form-item name="adsId" label="广告账户ID">
        <a-input
          v-model:value="searchFormState.adsId"
          placeholder="广告账户ID"
        ></a-input>
      </a-form-item>
      <!-- <a-form-item name="userId" label="用户ID">
        <a-input :model="searchFormState.userId" placeholder="用户ID"></a-input>
      </a-form-item> -->
      <a-form-item name="frozenstate" label="冻结状态">
        <a-select
          v-model:value="searchFormState.frozenState"
          style="min-width: 120px"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="0">正常</a-select-option>
          <a-select-option value="1">冻结</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="accountstate" label="账号状态">
        <a-select
          v-model:value="searchFormState.accountState"
          style="min-width: 120px"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">使用中</a-select-option>
          <a-select-option value="2">已停用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-right: 10px" @click="resetForm">重置</a-button>
        <a-button type="primary" @click="search" style="margin-right: 10px"
          >搜索</a-button
        >
        <!-- <a-button type="primary" @click="addClick">添加</a-button> -->
      </a-form-item>
    </a-form>
  </div>
  <div style="margin-top: 20px">
    <XAtable :columns="columns" :dataSource="tableData" :loading="loading">
      <template #frozenState="{ scope }">
        <span>{{ scope.record.frozenState === 1 ? "冻结" : "正常" }}</span>
      </template>
      <template #accountState="{ scope }">
        <span>{{ scope.record.accountState === 1 ? "使用中" : "已停用" }}</span>
      </template>
      <template #adsName="{ scope }">
        <div>
          <div v-show="scope.record.isAdsNameEdit">
            <a-input
              v-model:value="scope.record.adsName"
              style="margin: -5px 0"
              @blur="adsNameBlur(scope.record)"
              @pressEnter="adsNameEnter(scope.record)"
              :id="setInputRef(scope)"
            />
          </div>

          <div v-show="!scope.record.isAdsNameEdit">
            {{ scope.record.adsName
            }}<EditOutlined
              :style="{ color: '#ff6600', marginLeft: '10px' }"
              @click="editAdsName(scope.record)"
            />
          </div>
        </div>
      </template>
      <template #control="{ scope }">
        <a-button type="primary" size="small" @click="topUpClick(scope.record)"
          >额度充值</a-button
        >
        <a-button
          type="primary"
          size="small"
          style="margin-left: 10px"
          @click="topUpDetailClick(scope.record)"
          >充值明细</a-button
        >
        <a-button
          @click="bandingBMClick(scope.record)"
          type="primary"
          size="small"
          style="margin-left: 10px"
          >绑定BM</a-button
        >
      </template>
    </XAtable>
    <a-pagination
      v-model:current="pageNo"
      @change="pageNoChange"
      :total="total"
      hideOnSinglePage
      @showSizeChange="onShowSizeChange"
      style="margin-top: 5px"
    />
  </div>
  <x-dialog
    title="充值"
    :visible="topUpDislogVisible"
    @cancel="topUpDislogVisible = false"
  >
    <div class="top-up">
      <div class="top-up-money">
        <div>请输入您想充值的金额：</div>
        <a-input
          prefix="$"
          suffix="美元"
          placeholder="请输入金额(金额不能小于$100)"
          v-model:value="topUpValue"
          @input="limitAmount"
        ></a-input>
      </div>
      <div class="wallet">
        <!-- <div>是否用余额抵扣：</div>
        <a-radio-group v-model:value="isUseWallet">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group> -->
        <div style="margin-left: 20px">
          可用余额：<span style="color: orange; font-weight: 600"
            >${{ wallet }}</span
          >
        </div>
      </div>
      <div class="btn">
        <a-button
          type="primary"
          style="width: 200px"
          @click="payQrCodeClick"
          :loading="topUpLoading"
          :disabled="!topUpValue"
          >立即支付</a-button
        >
      </div>
    </div>
  </x-dialog>

  <x-dialog
    :title="title"
    :visible="qrcodeDislogVisible"
    @cancel="qrcodeCancle"
  >
    <div class="qrcode">
      <div>订单编号: {{ orderNum }}</div>
      <div class="qrcode-border">
        <canvas id="canvas"></canvas>
      </div>
      <div class="money">
        充值金额： <span>${{ totalTopUpValue }}</span>
      </div>
    </div>
  </x-dialog>

  <x-dialog
    title="充值明细"
    :visible="detailVisible"
    @cancel="detailVisible = false"
    :width="1100"
    :isDestroyOnClose="true"
  >
    <OrderDetail :adsId="curRow.adsId" />
  </x-dialog>

  <x-dialog
    title="BM绑定"
    :visible="bmDetailVisible"
    @cancel="bmDetailVisible = false"
    :width="500"
    :isDestroyOnClose="true"
  >
    <div>
      广告账号：<span style="color: gray">{{ curRow.adsId }}</span>
    </div>
    <div style="margin-top: 20px; display: flex; align-items: center">
      <div style="width: 100px">绑定BMID：</div>
      <a-input v-model:value="bmId" placeholder="输入绑定的BMID账号"></a-input>
    </div>
    <div style="display: flex; margin-top: 20px">
      <div style="width: 70px">权限：</div>
      <div class="form-my">
        <div v-for="(item, index) in authList" :key="index">
          {{ item.title }}：<a-switch
            v-model:checked="item.checked"
            size="small"
            @change="checkedChange"
          />
        </div>
      </div>
    </div>

    <div style="text-align: center; width: 100%; margin-top: 10px">
      <a-button type="primary" @click="bandingClick" :loading="bmLoading"
        >绑定</a-button
      >
    </div>
    <bm-list :adsId="curRow.adsId" :refresh="bmListRefresh"></bm-list>
  </x-dialog>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  computed,
} from "vue";
import XDialog from "@/components/XDialog.vue";
import XAtable from "@/components/XATable.vue";
import QRcode from "qrcode";
import {
  getPayInfo,
  getWxQRUrl,
  getPayResult,
  queryAccountList,
  userInfoDetail,
  accountsDel,
  queryAccountSave,
  bmSave,
  bmDel,
} from "@/api/api";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import OrderDetail from "./OrderDetail.vue";
import BmList from "./BmList.vue";
import { getUserInfo, setUserInfo } from "@/utils/userInfo";
import { EditOutlined } from "@ant-design/icons-vue";

type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  // appId: String | Number;
  // time: RangeValue;
  accountState: String;
  adsId: String;
  userId: String;
  frozenState: String;
  pageNo: Number;
  pageSize: Number;
}

interface FormState {
  accountState: Number;
  adsId: String;
  adsName: String;
  bmName: String;
  //   company: String;
  frozenState: Number;
  id: any;
  userId: String;
}

const qrcodeDislogVisible = ref(false);
const topUpDislogVisible = ref(false);
const bmDetailVisible = ref(false);
const searchFormState = reactive<SearchFormState>({
  frozenState: "",
  accountState: "",
  adsId: "",
  userId: "",
  pageNo: 1,
  pageSize: 10,
});
const formState = reactive<FormState>({
  accountState: 1,
  adsId: "",
  adsName: "",
  bmName: "",
  //   company: "",
  frozenState: 0,
  id: null,
  userId: "",
});

const isAdd = ref(1);
const dislogVisible = ref(false);
const addLoading = ref(false);
const pageNo = ref(1);
const pageSize = ref(10);
const total = ref(0);
const appList = ref([]);
const dateFormat = ref("YYYY-MM-DD");
const loading = ref(false);
const bmId = ref("");
const bmListRefresh = ref("");

const columns = reactive([
  {
    title: "广告账户ID",
    key: "adsId",
    dataIndex: "adsId",
  },
  {
    title: "广告账号名称",
    key: "adsName",
    dataIndex: "adsName",
    slots: {
      customRender: "adsName",
    },
  },
  {
    title: "开户主体",
    key: "bmName",
    dataIndex: "bmName",
  },
  {
    title: "子公司",
    key: "company",
    dataIndex: "company",
  },
  {
    title: "冻结状态",
    key: "frozenState",
    dataIndex: "frozenState",
    slots: {
      customRender: "frozenState",
    },
  },
  {
    title: "账号状态",
    key: "accountState",
    dataIndex: "accountState",
    slots: {
      customRender: "accountState",
    },
  },
  {
    title: "累积充值金额($)",
    key: "totalAmount",
    dataIndex: "totalAmount",
  },
  {
    title: "操作",
    key: "control",
    dataIndex: "control",
    slots: {
      customRender: "control",
    },
  },
]);
const tableData = ref<any[]>([]);
const formRef = ref<FormInstance>();
const searchFormRef = ref<FormInstance>();
const userList = ref([]);
const curRow = ref<any>();
const topUpValue = ref("");
const topUpLoading = ref(false);
const qrCodeText = ref("");
const orderNum = ref("");
const topUpTimer = ref();
const isUseWallet = ref(true);
const detailVisible = ref(false);
const wallet = ref(0);
const title = ref("二维码充值");
const totalTopUpValue = ref(0);
const inputs = [] as HTMLInputElement[];
const bmLoading = ref(false);
const authList = reactive([
  {
    title: "管理广告",
    checked: true,
  },
  {
    title: "查看表现",
    checked: true,
  },
  {
    title: "管理创意馆",
    checked: true,
  },
  {
    title: "高级分析",
    checked: true,
  },
]);
const authStr = ref("");

const setInputRef = (scope: any) => {
  return `adsName${scope.record.id}`;
};

const editAdsName = (row: any) => {
  row.isAdsNameEdit = !row.isAdsNameEdit;
  const el = document.getElementById(`adsName${row.id}`);
  if (el) {
    nextTick(() => {
      el.focus();
    });
  }
};

const search = () => {
  searchFormState.pageNo = 1;
  getData();
};

const resetForm = () => {
  searchFormState.pageNo = 1;
  if (searchFormRef.value) {
    searchFormRef.value.resetFields();
  }
  getData();
};

const getData = () => {
  loading.value = true;
  queryAccountList(searchFormState).then((res) => {
    loading.value = false;
    if (res.code === "200") {
      total.value = res.data.total;
      var list: any[] = [];
      res.data.records.forEach((val: any) => {
        var tempVal: any = JSON.parse(JSON.stringify(val));
        tempVal.isAdsNameEdit = false;
        list.push(tempVal);
      });
      tableData.value = list;
    }
  });
};
const pageNoChange = (val: number) => {
  searchFormState.pageNo = val;
  getData();
};
const onShowSizeChange = (current: any, size: number) => {
  searchFormState.pageSize = size;
  getData();
};
const selectChange = () => {
  searchFormState.pageNo = 1;
  getData();
};
const addClick = () => {
  isAdd.value = 1;
  dislogVisible.value = true;
  formState.accountState = 1;
  formState.adsId = "";
  formState.adsName = "";
  formState.bmName = "";
  //   formState.company = "";
  formState.frozenState = 0;
  formState.id = null;
  formState.userId = "";
};
const topUpClick = (row: any) => {
  topUpValue.value = "";
  curRow.value = row;
  topUpDislogVisible.value = true;
  getUserInfoDetail();
};

const payQrCodeClick = () => {
  if (!topUpValue.value) {
    return;
  }
  topUpLoading.value = true;
  getPayInfo({
    cny: Number(topUpValue.value),
    goodsType: 1,
    isUseWallet: isUseWallet.value,
    payName: curRow.value.adsId,
  }).then((res) => {
    if (res.code === "200") {
      if (res.data.state === 1) {
        topUpDislogVisible.value = false;
        topUpLoading.value = false;
        message.success("充值成功！");
        getData();
      } else {
        totalTopUpValue.value = res.data.total;
        getWxQRUrl({ id: res.data.id }).then((res) => {
          topUpLoading.value = false;
          if (res.code === "200") {
            topUpDislogVisible.value = false;
            qrcodeDislogVisible.value = true;
            title.value = curRow.value.adsId;
            qrCodeText.value = res.data.codeUrl;
            orderNum.value = res.data.orderNo;
            generateQRCode(res.data.codeUrl);
            getTopUpResult();
          }
        });
      }
    } else {
      message.error(res.message);
      topUpLoading.value = false;
    }
  });
};

const getTopUpResult = () => {
  clearInterval(topUpTimer.value);
  topUpTimer.value = setInterval(() => {
    getPayResult({ orderNo: orderNum.value }).then((res) => {
      if (res.code === "200") {
        if (res.data.payResult) {
          clearInterval(topUpTimer.value);
          topUpTimer.value = null;
          message.success("充值成功！");
          qrcodeDislogVisible.value = false;
          getData();
        }
      }
    });
  }, 1500);
};

const generateQRCode = (text: string) => {
  nextTick(() => {
    const canvas = document.getElementById("canvas");
    QRcode.toCanvas(canvas, text, {
      margin: 0,
      width: 200,
    });
  });
};

const qrcodeCancle = () => {
  qrcodeDislogVisible.value = false;
  clearInterval(topUpTimer.value);
  topUpTimer.value = null;
};

const limitAmount = (event: any) => {
  topUpValue.value = event.target.value
    .replace(/[^\d.]/g, "")
    // 限制只有一个小数点
    .replace(/^\./g, "")
    .replace(/\.{2,}/g, ".")
    // 限制小数点后只能有两位
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".")
    .replace(/^(\-)*(\d+)\.(\d{2}).*$/, "$1$2.$3");
};

const topUpDetailClick = (row: any) => {
  curRow.value = row;
  detailVisible.value = true;
};

const getUserInfoDetail = () => {
  userInfoDetail({}).then((res) => {
    loading.value = false;
    if (res.code === "200") {
      setUserInfo(res.data);
      wallet.value = getUserInfo().wallet ? getUserInfo().wallet : 0;
    }
  });
};

const confirmDel = (row: any) => {
  accountsDel({ id: row.id }).then((res) => {
    if (res.code === "200") {
      message.success("删除成功！");
      getData();
    }
  });
};
const cancelDel = () => {};
const isEnter = ref(false);
const adsNameBlur = (row: any) => {
  if (isEnter.value) {
    return;
  }
  row.isAdsNameEdit = !row.isAdsNameEdit;
  isEnter.value = false;
  queryAccountSave({
    adsName: row.adsName,
    bmName: row.bmName,
    id: row.id,
  }).then((res) => {
    if (res.code === "200") {
      message.success("修改成功！");
    }
  });
};
const adsNameEnter = (row: any) => {
  isEnter.value = true;
  row.isAdsNameEdit = !row.isAdsNameEdit;
  queryAccountSave({
    adsName: row.adsName,
    bmName: row.bmName,
    id: row.id,
  }).then((res) => {
    if (res.code === "200") {
      isEnter.value = false;
      message.success("修改成功！");
    }
  });
};
const bandingBMClick = (row: any) => {
  curRow.value = row;
  bmDetailVisible.value = true;
  checkedChange();
};
const bandingClick = () => {
  bmLoading.value = true;
  bmSave({ adsId: curRow.value.adsId, bmId: bmId.value, auth: authStr.value }).then(
    (res) => {
      bmLoading.value = false;
      if (res.code === "200") {
        message.success("绑定成功！");
        bmListRefresh.value = String(Number(new Date()));
      }else{
        message.error(res.message);
      }
    }
  );
};
const checkedChange = () => {
  var authSelected = authList.filter((val) => val.checked);
  var authSelectedTitles: string[] = [];
  authSelected.forEach((val) => {
    authSelectedTitles.push(val.title);
  });
  authStr.value = authSelectedTitles.join(",");
  console.log(authStr.value);
};
onMounted(() => {
  wallet.value = getUserInfo().wallet ? getUserInfo().wallet : 0;
  getData();
});
</script>

<style scoped lang="less">
.wallet {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.top-up {
  margin-top: 30px;
  .top-up-money {
    display: flex;
    align-items: center;
    div {
      width: 250px;
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}
.qrcode {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .qrcode-border {
    margin-top: 20px;
    width: 220px;
    height: 220px;
    background: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .money {
    margin-top: 20px;
    span {
      color: orange;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.form-my {
  div {
    float: left;
    width: 150px;
  }
}
</style>
