import request from "@/utils/request";

export function logout(data: any) {
  return request({
    url: "/ws/user/logout",
    method: "post",
    data,
  });
}

export function getReward(data: any) {
  return request({
    url: "App.Reward.getReward",
    method: "post",
    data,
  });
}

export function buy(data: any) {
  return request({
    url: "ads/buy",
    method: "post",
    data,
  });
}
//用户详细信息
export function userInfoDetail(data: any) {
  return request({
    url: "/ws/user/detail",
    method: "post",
    data,
  });
}
//登录
export function userLogin(data: any) {
  return request({
    url: "/ws/user/login",
    method: "post",
    data,
  });
}

//获取支付信息
export function getPayInfo(data: any) {
  return request({
    url: "/ws/pay/getPayInfo",
    method: "post",
    data,
  });
}

//二维码
export function getWxQRUrl(data: any) {
  return request({
    url: "/ws/pay/getWxQRUrl",
    method: "post",
    data,
  });
}

//获取支付结果
export function getPayResult(data: any) {
  return request({
    url: "/ws/pay/getPayResult",
    method: "post",
    data,
  });
}

//账号管理列表
export function queryAccountList(data: any) {
  return request({
    url: "/ads/queryList",
    method: "get",
    params:data,
  });
}
//账号管理列表编辑
export function queryAccountSave(data: any) {
  return request({
    url: "/ads/save",
    method: "post",
    data,
  });
}
//获取订单列表
export function queryOrderList(data: any) {
  return request({
    url: "/ws/pay/ads/payList",
    method: "get",
    params:data,
  });
}
//账户列表删除
export function accountsDel(data: any) {
  return request({
    url: "/ads/del",
    method: "post",
    data,
  });
}
//BM账户列表
export function bmList(data: any) {
  return request({
    url: "/bm/queryList",
    method: "get",
    params:data,
  });
}
//BM绑定
export function bmSave(data: any) {
  return request({
    url: "/bm/save",
    method: "post",
    data,
  });
}
//BM解绑
export function bmDel(data: any) {
  return request({
    url: "/bm/del",
    method: "post",
    data,
  });
}

