import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "@/style/index.less"

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Antd);
app.mount("#app");

router.beforeEach((to, from, next) => {
  // Update the document title based on the route
  document.title = String(to.meta.title || "雨果广告平台");
  next();
});
