import axios from "axios";
import { getToken, removeToken } from '@/utils/auth';
import globalConfig from "./config";
import { message } from "ant-design-vue";
// import store from '@/store'
import router from "@/router";
// import { encrypt, decrypt, uuid_my } from "@/utils/crypto";

const baseURL = globalConfig.baseUrl;
const service = axios.create({
  baseURL: baseURL, //process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 15000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.data) {
      config.data = {};
    }
    if (getToken()) {
      config.headers["t"] = getToken();
      config.headers["source"] = "app";
    }
    // var params = {
    //   lang_type: 3,
    //   version_code: 1,
    //   channel: "default",
    //   os_type: "android",
    //   token: "",
    //   sysId: 1,
    //   deviceId: `web-${uuid_my(16,16)}`,
    // };
    // params.token = config.data.token;
    // const encryptStr = encrypt(params);
    // config.headers["data"] = encryptStr;
    // config.headers
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
let status = true;
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log(res)
    if(res.code === "402"){
      router.replace('/login')
      message.error(res.message)
    }
    return res;
  },
  (error) => {
    console.log("err" + error);
    // message.error(error.message); Promise.reject(error)
    return false;
  }
);
declare module "axios" {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}
export default service;
