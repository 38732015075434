<template>
  <div style="margin-top: 20px">
    <XAtable :columns="columns" :dataSource="tableData" :loading="loading">
      <template #payResult="{ scope }">
        <a-tag v-if="scope.record.payResult === 0" >未支付</a-tag>
        <a-tag v-if="scope.record.payResult === 1" color="green">已支付</a-tag>
        <a-tag v-if="scope.record.payResult === 2" color="red">已关闭</a-tag>
        <a-tag v-if="scope.record.payResult === 5" color="blue">已退回</a-tag>
      </template>
      <template #isUseWallet="{ scope }">
        <span>{{ scope.record.isUseWallet === true ? "是" : "否" }}</span>
      </template>
      <template #toPayAccount="{ scope }">
        <a-tag v-if="scope.record.toPayAccount === 0" >未充值</a-tag>
        <a-tag v-if="scope.record.toPayAccount === 1" color="gray"
          >充值中</a-tag
        >
        <a-tag v-if="scope.record.toPayAccount === 2" color="green"
          >已充值</a-tag
        >
        <a-tag v-if="scope.record.toPayAccount === 3" color="blue"
          >已退回</a-tag
        >
      </template>
      <template #control="{ scope }">
        <a-button type="primary" size="small" @click="topUpClick(scope.record)"
          >详情</a-button
        >
      </template>
    </XAtable>
    <a-pagination
      v-model:current="pageNo"
      @change="pageNoChange"
      :total="total"
      hideOnSinglePage
      @showSizeChange="onShowSizeChange"
      style="margin-top: 5px"
    />
  </div>
  <x-dialog
    title="详情"
    :visible="topUpDislogVisible"
    @cancel="topUpDislogVisible = false"
    width="2000px"
  >
  </x-dialog>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  defineProps,
  watch,
  onActivated,
} from "vue";
import XDialog from "@/components/XDialog.vue";
import XAtable from "@/components/XATable.vue";
import { queryOrderList } from "@/api/api";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  time: RangeValue;
  orderNo: String;
  pageNo: Number;
  pageSize: Number;
  beginTime: String;
  endTime: String;
}

interface FormState {
  accountState: Number;
  adsId: String;
  adsName: String;
  bmName: String;
  frozenState: Number;
  id: any;
  userId: String;
}
const props = defineProps<{ adsId: string }>();
const qrcodeDislogVisible = ref(false);
const topUpDislogVisible = ref(false);
const searchFormState = reactive<SearchFormState>({
  time: [
    dayjs(new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)),
    dayjs(new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 1)),
  ],
  orderNo: "",
  pageNo: 1,
  pageSize: 10,
  beginTime: "",
  endTime: "",
});
const formState = reactive<FormState>({
  accountState: 1,
  adsId: "",
  adsName: "",
  bmName: "",
  //   company: "",
  frozenState: 0,
  id: null,
  userId: "",
});

const isAdd = ref(1);
const dislogVisible = ref(false);
const addLoading = ref(false);
const pageNo = ref(1);
const pageSize = ref(10);
const total = ref(0);
const appList = ref([]);
const dateFormat = ref("YYYY-MM-DD");
const loading = ref(false);
const columns = reactive([
  {
    title: "广告账户ID",
    key: "adsId",
    dataIndex: "adsId",
  },
  {
    title: "订单编号",
    key: "outTradeNo",
    dataIndex: "outTradeNo",
  },
  {
    title: "钱包抵扣",
    key: "isUseWallet",
    dataIndex: "isUseWallet",
    slots: {
      customRender: "isUseWallet",
    },
  },
  {
    title: "钱包抵扣金额(￥)",
    key: "walletOutTotal",
    dataIndex: "walletOutTotal",
  },
  {
    title: "支付金额(￥)",
    key: "total",
    dataIndex: "total",
  },
  {
    title: "支付结果",
    key: "payResult",
    dataIndex: "payResult",
    slots: {
      customRender: "payResult",
    },
  },
  {
    title: "广告账户",
    key: "toPayAccount",
    dataIndex: "toPayAccount",
    slots: {
      customRender: "toPayAccount",
    },
  },
  {
    title: "支付时间",
    key: "payTime",
    dataIndex: "payTime",
  },
]);
const tableData = ref([]);
const formRef = ref<FormInstance>();
const searchFormRef = ref<FormInstance | undefined>();
const userList = ref([]);
const curRow = ref<any>();
const topUpValue = ref("");
const topUpLoading = ref(false);
const qrCodeText = ref("");
const orderNum = ref("");
const topUpTimer = ref();

watch(
  () => props.adsId,
  (val) => {
    nextTick(() => {
      getData();
    });
  },
  { immediate: true }
);

const search = () => {
  pageNo.value = 1;
  getData();
};
const resetForm = () => {
  pageNo.value = 1;
  if (searchFormRef.value != undefined) {
    searchFormRef.value.resetFields();
  }
  getData();
};
onActivated(() => {
  console.log(123123);
});

const getData = () => {
  loading.value = true;
  var params = {
    // beginTime:
    //   dayjs(searchFormState.time[0]).format(dateFormat.value) + " 00:00:00",
    // endTime:
    //   dayjs(searchFormState.time[1]).format(dateFormat.value) + " 00:00:00",
    adsId: props.adsId,
    pageNo: pageNo.value,
    pageSize: pageSize.value,
  };
  queryOrderList(params).then((res) => {
    loading.value = false;
    if (res.code === "200") {
      total.value = res.data.total;
      tableData.value = res.data.records;
    }
  });
};
const pageNoChange = (val: number) => {
  pageNo.value = val;
  getData();
};
const onShowSizeChange = (current: any, size: number) => {
  pageSize.value = size;
  getData();
};
const selectChange = () => {
  pageNo.value = 1;
  getData();
};

const topUpClick = (row: any) => {
  curRow.value = row;
  topUpDislogVisible.value = true;
};

const limitAmount = (event: any) => {
  topUpValue.value = event.target.value
    .replace(/[^\d.]/g, "")
    // 限制只有一个小数点
    .replace(/^\./g, "")
    .replace(/\.{2,}/g, ".")
    // 限制小数点后只能有两位
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".")
    .replace(/^(\-)*(\d+)\.(\d{2}).*$/, "$1$2.$3");
};
onMounted(() => {
  //   getData();
});
</script>

<style scoped lang="less">
.top-up {
  margin-top: 30px;
  .top-up-money {
    display: flex;
    align-items: center;
    div {
      width: 250px;
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}
.qrcode {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .qrcode-border {
    margin-top: 20px;
    width: 220px;
    height: 220px;
    background: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .money {
    margin-top: 20px;
    span {
      color: orange;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
