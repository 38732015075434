const TokenKey = "USER-INFO";

export function getUserInfo() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(TokenKey) ? JSON.parse(String(localStorage.getItem(TokenKey))) : "";
}

export function setUserInfo(data: string) {
  // return Cookies.set(TokenKey, token, { expires: 7, path: '/' })
  return localStorage.setItem(TokenKey, JSON.stringify(data));
}

export function removeUserInfo() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem(TokenKey);
}
