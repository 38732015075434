import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layuout from "../layout/index.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Accounts from "@/views/Accounts.vue";
import OrderList from '@/views/OrderList.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layuout,
    redirect:"/home",
    meta:{
      title:"首页"
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        meta:{
          title:"首页"
        }
      }
      
    ],
  },
  {
    path: "/account_manage",
    redirect:"/accounts",
    component: Layuout,
    meta:{title:"广告"},
    children: [
      {
        path: "/accounts",
        name: "account",
        component: Accounts,
        meta:{
          title:"账户列表",
        }
      },
    ],
  },
  {
    path: "/order_manage",
    redirect:"/orders",
    component: Layuout,
    meta:{title:"财务"},
    children: [
      {
        path: "/orders",
        name: "OrderList",
        component: OrderList,
        meta:{
          title:"交易记录",
        }
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
