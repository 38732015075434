import { createStore } from "vuex";
import { getToken, removeToken } from "@/utils/auth";
import { getUserInfo } from "@/utils/userInfo";

export default createStore({
  state: {
    isLogin: getToken() ? true : false,
    userInfo: getUserInfo() ? getUserInfo() : ""
  },
  getters: {},
  mutations: {
    SET_ISLOGIN(state, data) {
      state.isLogin = data;
    },
  },
  actions: {
    is_login(context, data) {
      context.commit("SET_ISLOGIN", data);
    },
  },
  modules: {},
});
