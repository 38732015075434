import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    dataSource: $setup.tableData,
    columns: $setup.column,
    bordered: false,
    scroll: $props.scroll,
    loading: $setup.tableLoading,
    pagination: false,
    "row-selection": $setup.isSelections ? $setup.rowSelection : undefined,
    "custom-row": $setup.isSelections ? $setup.customRow : undefined
  }, _createSlots({
    headerCell: _withCtx(({ column }) => [
      _renderSlot(_ctx.$slots, "headerCell", { column: column })
    ]),
    bodyCell: _withCtx(({ column, record, index }) => [
      (!column.isMinute)
        ? _renderSlot(_ctx.$slots, column.key, {
            key: 0,
            record: record,
            index: index
          })
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode(_toDisplayString(column.symbol) + _toDisplayString(record[column.key] / 100), 1)
          ])
    ]),
    _: 2
  }, [
    _renderList($setup.slots, (item, index) => {
      return {
        name: item.slots.customRender,
        fn: _withCtx((row) => [
          (item.slots)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, item.slots.customRender, { scope: row })
              ]))
            : _createCommentVNode("", true)
        ])
      }
    })
  ]), 1032, ["dataSource", "columns", "scroll", "loading", "row-selection", "custom-row"]))
}