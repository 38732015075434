<template>
  <div>
    <a-layout>
      <Navbar />
      <a-layout has-sider class="content">
        <Siderbar />
        <a-layout style="padding: 0px 0px 0px 1px; box-sizing: border-box">
          <div>
            <a-page-header
            style="padding: 10px 10px 5px 8px; box-sizing: border-box"
              :breadcrumb="{ routes }"
            />
          </div>
          <Mainapp />
        </a-layout>
      </a-layout> </a-layout
    > 
  </div>
</template>
<script lang="ts" setup>
import { Options, Vue } from "vue-class-component";
import Mainapp from "@/layout/components/Mainapp.vue";
import Navbar from "@/layout/components/Navbar.vue";
import Siderbar from "@/layout/components/Siderbar.vue";
import { onMounted, watch, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
var routes = ref()
var unregisterWatcher = ref();

watch(
  () => route.path,
  (path) => {
    setBreadcrumbName()
  }
);
const setBreadcrumbName = () => {
    var routerTemp: { breadcrumbName: unknown }[] = []
    route.matched.forEach(val => {
        routerTemp.push({breadcrumbName:val.meta.title})
    })
    routes.value = routerTemp
}
onMounted(() => {
  setBreadcrumbName()
});
</script>

<style scoped>
.content {
  margin-top: 45px;
  height: calc(100vh - 44px);
}
</style>
