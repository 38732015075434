<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="banner">
          <button
            type="button"
            class="btn-action"
            data-v-2d30bf86=""
            @click="topUpClick"
          >
            <!----><!----><span
              ><i class="ygw-icon ygw-recharge-in icon"></i>立即充值
            </span>
          </button>
          <div>
            <div class="icon-wside">
              <span class="icon-box">
                <WalletOutlined
                  :style="{
                    fontSize: '41px',
                    color: '#f60',
                  }"
                />
              </span>
              <div class="amount-box">
                <div>钱包余额</div>
                <div class="amount" >
                  <span v-if="!isLogin" @click="router.replace('/login')">$9999</span>
                  <span v-else>${{ wallet ? wallet : "0.00" }}</span>
                  <!-- <RightOutlined :style="{ fontSize: '16px' }" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <a-row :gutter="20">
            <a-col :span="8">
              <section class="platform-card platform-card--not-open">
                <div>
                  <Facebook color="#217eda" :width="35" :height="35" />
                  <div class="title">Facebook</div>
                  <div>
                    <a-button
                      :icon="h(RightCircleFilled)"
                      :style="{
                        background: 'linear-gradient(-62deg, #0058ff, #0091ff)',
                        width: '240px',
                        height: '46px',
                        marginTop: '20px',
                      }"
                      type="primary" 
                      @click="facebookClick"
                      >Facebook 立即开通</a-button
                    >
                  </div>
                  <div class="platform-card__body" style="margin-top: 96px">
                    <ul style="padding: 0 30px 0 20px">
                      <li>
                        <span class="icon-box blue"
                          ><svg aria-hidden="true" class="icon svg-icon">
                            <use xlink:href="#icon-exclusive"></use></svg></span
                        ><span class="text"
                          >雨果跨境客户专享, Facebook官方广告投放补贴</span
                        >
                      </li>
                      <li>
                        <span class="icon-box blue"
                          ><svg aria-hidden="true" class="icon svg-icon">
                            <use xlink:href="#icon-channel"></use></svg></span
                        ><span class="text"
                          >雨果跨境专属绿色通道, 降低账号违规和被封风险</span
                        >
                      </li>
                      <li>
                        <span class="icon-box blue"
                          ><svg aria-hidden="true" class="icon svg-icon">
                            <use xlink:href="#icon-plan"></use></svg></span
                        ><span class="text"
                          >Facebook&amp;雨果跨境, 电商客户雏鹰计划</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </section></a-col
            >
            <a-col :span="8">
              <!-- <section class="platform-card platform-card--open">
                <div>
                  <div class="account">
                    <div class="logo">
                      <div><Google :width="35" :height="35" /></div>
                      <div class="title">
                        <div>Google</div>
                        <div class="sub-title">账户概括</div>
                      </div>
                    </div>
                    <div class="amount">
                      <div class="amount-text">账户余额</div>
                      <div class="amount-num">$500.00</div>
                    </div>
                  </div>
                  <div>
                    <a-button
                      :icon="h(RightCircleFilled)"
                      :style="{
                        background: 'linear-gradient(-48deg, #ffa516, #ffca13)',
                        width: '240px',
                        height: '46px',
                        marginTop: '20px',
                      }"
                      type="primary"
                      >Google 免费开户</a-button
                    >
                  </div>
                  <div class="platform-card__body" style="margin-top: 27px">
                    <div class="flex-center account-total yellow">
                      <div class="width-style">
                        <div class="total-box other">
                          <span class="left"
                            ><span class="name">账户总数</span></span
                          ><span class="right">
                            160
                            <svg
                              aria-hidden="true"
                              class="icon icon-arrow svg-icon"
                            >
                              <use xlink:href="#icon-arrow"></use>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <ul class="account-list">
                      <li>
                        <span class="left"><Light />&nbsp; 活跃账户 </span
                        ><span class="right"
                          ><span class="number"
                            ><svg
                              aria-hidden="true"
                              class="icon icon-new svg-icon"
                            >
                              <use xlink:href="#icon-new"></use>
                            </svg>
                            140
                          </span></span
                        >
                      </li>
                      <li>
                        <span class="left"><Lock />&nbsp; 冻结账户 </span
                        ><span class="right"
                          ><span class="number"
                            ><svg
                              aria-hidden="true"
                              class="icon icon-new svg-icon"
                            >
                              <use xlink:href="#icon-new"></use>
                            </svg>
                            10
                          </span></span
                        >
                      </li>
                      <li>
                        <span class="left"><Notice />&nbsp; 停用账户 </span
                        ><span class="right"
                          ><span class="number"
                            ><svg
                              aria-hidden="true"
                              class="icon icon-new svg-icon"
                            >
                              <use xlink:href="#icon-new"></use>
                            </svg>
                            10
                          </span></span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </section> -->
              <section class="platform-card">
                <Google :width="35" :height="35" />
                <div class="title">Google</div>
                <div>
                  <a-button
                    :icon="h(RightCircleFilled)"
                    :style="{
                      background: 'linear-gradient(-48deg,#ffa516,#ffca13)',
                      width: '240px',
                      height: '46px',
                      marginTop: '20px',
                    }"
                    type="primary"
                    >Google 立即开通</a-button
                  >
                </div>
                <div class="platform-card__body" style="margin-top: 96px">
                  <ul style="padding: 0 30px 0 20px">
                    <li>
                      <span class="icon-box yellow"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use xlink:href="#icon-exclusive"></use></svg></span
                      ><span class="text"
                        >雨果跨境客户专享, 免费获取 Buy On Google或Free Listings流量</span
                      >
                    </li>
                    <li>
                      <span class="icon-box yellow"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use
                            xlink:href="#icon-plan"
                          ></use></svg></span
                      ><span class="text"
                        >Google&雨果跨境, 电商孵化春田计划</span
                      >
                    </li>
                    <li>
                      <span class="icon-box yellow"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use xlink:href="#icon-unlock"></use></svg></span
                      ><span class="text"
                        >雨果跨境官方绿色通道, GMC快速解封</span
                      >
                    </li>
                  </ul>
                </div>
              </section>
            </a-col>
            <a-col :span="8"
              >
              <section class="platform-card">
                <TikTok :width="35" :height="35" />
                <div class="title">TikTok</div>
                <div>
                  <a-button
                    :icon="h(RightCircleFilled)"
                    :style="{
                      background: 'linear-gradient(-48deg,#00c6d4,#00d9e9)',
                      width: '240px',
                      height: '46px',
                      marginTop: '20px',
                    }"
                    type="primary"
                    >TikTok 立即开通</a-button
                  >
                </div>
                <div class="platform-card__body" style="margin-top: 96px">
                  <ul style="padding: 0 30px 0 20px">
                    <li>
                      <span class="icon-box sky-blue"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use xlink:href="#icon-group"></use></svg></span
                      ><span class="text"
                        >TikTok新手卖家成长计划， 绿色通道极速开户</span
                      >
                    </li>
                    <li>
                      <span class="icon-box sky-blue"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use
                            xlink:href="#icon-brand-out-sea"
                          ></use></svg></span
                      ><span class="text"
                        >TikTok联合雨果跨境， 赋能品牌出海</span
                      >
                    </li>
                    <li>
                      <span class="icon-box sky-blue"
                        ><svg aria-hidden="true" class="icon svg-icon">
                          <use xlink:href="#icon-server"></use></svg></span
                      ><span class="text"
                        >TikTok官方针对性服务， 制定专属营销计划</span
                      >
                    </li>
                  </ul>
                </div>
              </section></a-col
            >
          </a-row>
        </div>
      </a-col>
      <!-- <a-col :span="4"><div></div></a-col> -->
    </a-row>
  </div>
</template>
<script lang="ts" setup>
import { h, watch, ref, onMounted } from "vue";
import {
  WalletOutlined,
  RightOutlined,
  RightCircleFilled,
} from "@ant-design/icons-vue";
import Facebook from "@/assets/images/svg/facebook.vue";
import Google from "@/assets/images/svg/Google.vue";
import TikTok from "@/assets/images/svg/TikTok.vue";
import Notice from "@/assets/images/svg/Notice.vue";
import Light from "@/assets/images/svg/Light.vue";
import Lock from "@/assets/images/svg/Lock.vue";
import { useStore } from "vuex";
import { getUserInfo,setUserInfo } from "@/utils/userInfo";
import XDialog from "@/components/XDialog.vue";
import { useRouter } from 'vue-router';
import {userInfoDetail} from '@/api/api';

const router = useRouter()
const store = useStore();
const isLogin = ref(false);
const wallet = ref("");
const topUpDislogVisible = ref(false)
watch(
  () => store.state.isLogin,
  (val) => {
    console.log(val);
    isLogin.value = val;
  },
  { immediate: true }
);

onMounted(() => {
  wallet.value = getUserInfo().wallet;
  getUserInfoDetail()
});
const getUserInfoDetail = () => {
  userInfoDetail({}).then((res) => {
    if (res.code === "200") {
      setUserInfo(res.data);
      wallet.value = getUserInfo().wallet ? getUserInfo().wallet : 0;
    }
  });
};
const payQrCodeClick = () => {

};
const facebookClick = () => {
    // location.href = "https://www.facebook.com/chinabusinesses/onboarding/141116910633456/?token=2320265088166430"
    window.open("https://www.facebook.com/chinabusinesses/onboarding/141116910633456/?token=2320265088166430")
}
const topUpClick = () => {
    topUpDislogVisible.value = true
}
</script>
<style scoped lang="less">

.banner {
  background-image: url("https://iws-customer-static.cifnews.com/p/customer/account/dashboard-precharge-banner.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.btn-action {
  width: 190px;
  height: 60px;
  background: linear-gradient(-3deg, #ff6314, #ffd470);
  border: 3px solid #ffdca5;
  -webkit-box-shadow: 0 10px 8px 1px rgba(143, 42, 27, 0.38);
  box-shadow: 0 10px 8px 1px rgba(143, 42, 27, 0.38);
  border-radius: 30px;
  margin: 0 24px 0 42px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.icon-wside {
  display: flex;
  .icon-box {
    display: flex;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: #fff;
    border: 1px solid #ffc060;
    border-radius: 8px;
    margin-right: 15px;
  }

  .amount-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    margin-top: 5px;
    .amount {
      font-weight: 600;
      font-size: 32px;
      margin-top: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
.card-content {
  .platform-card--not-open {
    padding: 40px 10px 100px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .platform-card--open {
    padding: 25px 17px 40px;
    .account {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 30px;
      .logo {
        display: flex;
        align-items: center;
        .title {
          font-size: 18px;
          margin-top: 0px;
          margin-left: 10px;
          font-weight: 600;
          .sub-title {
            font-size: 14px;
            color: #999;
            font-weight: 400;
          }
        }
      }
      .amount {
        text-align: right;
        .amount-num {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
  .platform-card {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    min-height: 650px;
    text-align: center;
    margin-top: 20px;
    padding: 40px 10px 40px;
    .title {
      font-size: 16px;
      margin-top: 15px;
      font-weight: 600;
    }
    .platform-card__body {
      width: 100%;
      .account-total {
        height: 130px;
        border-radius: 10px;
        padding: 10px 20px;
        color: #333;
        margin-bottom: 31px;
        .width-style {
          width: 100%;
        }
        .total-box.other {
          margin-top: -20px;
        }
        .total-box {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .name {
            font-size: 14px;
          }
        }
        .right {
          font-size: 24px;
          font-weight: 600;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: pointer;
          .icon-arrow {
            width: 12px;
            height: 12px;
          }
        }
        .icon-arrow {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          margin-left: 7px;
          color: #999;
        }
      }
      .flex-center {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      .yellow {
        background: rgba(255, 250, 241, 0.8);
        color: #ff9f07;
      }
      .account-list {
        padding: 0 10px 0 0;
        li {
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin: 0 30px 40px;
          text-align: -webkit-match-parent;
          .left {
            font-size: 12px;
            display: flex;
            align-items: center;
            .ygw-icon.ygw-lemp {
              background: #fdf3ec;
              color: #f60;
            }
            .ygw-icon {
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              margin-right: 16px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              font-size: 18px;
            }
          }
          .right {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-weight: 600;
            line-height: 1;
            .number {
              position: relative;
              .icon-new {
                position: absolute;
                bottom: 100%;
                color: #ff2020;
                width: 20px;
                height: 20px;
                right: -12px;
              }
            }
          }
        }
      }
    }
    .blue {
      background: rgba(36, 116, 252, 0.2);
      color: #007fee;
    }
    .sky-blue {
      background: rgba(0, 217, 233, 0.2);
      color: #00d9e9;
    }
    .yellow{
        background: rgba(255,165,22,.2);
        color:#ff9f07
    }
    li {
      display: flex;
      margin-bottom: 60px;
      font-size: 14px;
      line-height: 20px;
      .icon-box {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 6px;
        margin-right: 18px;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      span {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        color: #333;
        font: inherit;
        text-decoration: none;
        vertical-align: baseline;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        word-break: break-all;
        text-align: left;
      }
    }
  }
}
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.ygw-icon {
  font-family: iconfont !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
