<template>
  <a-layout-content
    :style="{
      background: '#f5f5f5',
      padding: '10px',
      margin: 0,
      minHeight: '280px',
      overflow: 'auto',
    }"
  >
    <router-view />
  </a-layout-content>
</template>
