<template>
  <div class="login-hud">
    <div class="login-box">
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        style="margin-top: 20px"
      >
        <a-form-item
          label="手机号"
          name="account"
          :rules="[{ required: true, message: '请输入手机号!' }]"
        >
          <a-input v-model:value="formState.account" placeholder="输入手机号" />
        </a-form-item>

        <a-form-item
          label="密码"
          name="password"
          :rules="[{ required: true, message: '请输入密码!' }]"
        >
          <a-input-password
            v-model:value="formState.password"
            placeholder="输入密码"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 6, span: 18 }">
          <a-button type="primary" html-type="submit" style="width: 100%" :loading="loading"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive, ref, onMounted } from "vue";
import { userLogin, userInfoDetail } from "@/api/api";
import { setToken } from "@/utils/auth";
import { setUserInfo } from "@/utils/userInfo";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

interface FormState {
  account: string;
  password: string;
  type: number;
}

const loading = ref(false);

const formState = reactive<FormState>({
  account: "",
  password: "",
  type: 0,
});

const onFinish = (values: any) => {
  console.log("Success:", values);
  loading.value = true;
  userLogin(formState).then((res) => {
    if (res.code === "200") {
      setToken(res.data.token);
      store.dispatch("is_login", true);
      getUserInfoDetail();
    }
  });
};

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const getUserInfoDetail = () => {
  userInfoDetail({}).then((res) => {
    loading.value = false;
    if (res.code === "200") {
      setUserInfo(res.data);
      router.push("/");
    }
  });
};

onMounted(() => {});
</script>
<style scoped lang="less">
.login-hud {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 30px 10px 10px;
}
</style>
