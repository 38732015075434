<template>
  <a-layout-header
    class="header"
    :style="{ position: 'fixed', zIndex: 1, width: '100%', height: '45px' }"
  >
    <div class="logo">雨果广告平台</div>
    <div class="header-right">
      <a-button type="primary" v-if="!isLogin" @click="router.replace('/login')">去登录</a-button>
      <a-dropdown v-else>
        <a class="ant-dropdown-link" @click.prevent>
          <a-image
            style="border-radius: 50%"
            :width="30"
            :height="30"
            :src="require('@/assets/images/header.png')"
            :fallback="require('@/assets/images/header.png')"
          />
          <span class="username">{{ userName }}</span>
          <CaretDownOutlined />
        </a>
        <template #overlay>
          <div class="head">
            <div class="head-box">
              <div class="head-image">
                <a-image
                  style="border-radius: 50%"
                  :width="70"
                  :height="70"
                  src="https://www.antdv.com/#error"
                  :fallback="require('@/assets/images/header.png')"
                >
                </a-image>
              </div>
              <div class="head-title">
                <div class="head-username">{{ userName }}</div>
                <div class="head-phone">手机：{{ phoneNum }}</div>
                <div class="head-company">公司：{{  company }}</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="head-bottom">
              <a-button type="text"
                ><template #icon><UserOutlined /></template>个人中心</a-button
              >
              <a-button type="text" @click="loginOut"
                ><template #icon
                  ><PoweroffOutlined :style="{ color: 'red' }" /></template
                >退出登录</a-button
              >
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>
<script setup lang="ts">
import {
  CaretDownOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { watch, ref, onMounted } from "vue";
import { getUserInfo } from "@/utils/userInfo";
import { logout } from "@/api/api";
import { removeToken } from "@/utils/auth";
import { removeUserInfo } from "@/utils/userInfo";
import { useRouter } from 'vue-router';

const router = useRouter()
const store = useStore();
const isLogin = ref(false);
let userName = ref();
let phoneNum = ref();
let company = ref();
watch(
  () => store.state.isLogin,
  (val) => {
    console.log(val);
    isLogin.value = val;
  },
  { immediate: true }
);
const loginOut = () => {
  logout({}).then((res) => {
    if (res.code === "200") {
      removeToken();
      removeUserInfo();
      router.replace("/login")
      store.dispatch("is_login", false);
    }
  });
};
onMounted(() => {
  userName.value = getUserInfo().name;
  phoneNum.value = getUserInfo().mobile;
  company.value = getUserInfo().company;
});
</script>
<style scoped lang="less">
.header {
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px !important;
}
.header-right {
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
  color: white !important;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
.navbar .left-menu .logo-line .logo-word {
  width: 186px !important;
  height: 24px !important;
  margin: -9px 20px 0 15px;
}
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.ant-dropdown-link {
  color: white !important;
}
.username {
  font-size: 16px;
  font-weight: 600;
  padding: 0 2px 0 10px;
}
.head {
  margin-top: 20px;
  margin-right: 0px;
  background-color: white;
  padding: 10px 20px;
  .line {
    height: 1px;
    background: #f5f5f5;
    width: 100%;
    margin: 0 0 10px 0;
    box-sizing: border-box;
  }
  .head-box {
    background: #fff;
    display: flex;
    align-items: center;
    .head-image {
      padding: 15px 15px 15px 0;
    }
    .head-title {
      .head-username {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .head-bottom {
    display: flex;
    justify-content: space-between;
  }
}
</style>
