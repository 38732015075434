<template>
  <a-layout-sider
    width="200"
    :style="{
      overflow: 'auto',
      height: '100vh',
      //   position: 'fixed',
      left: 0,
      //   top: '64px',
      bottom: 0,
    }"
    :collapsed="true"
    :trigger="null"
    collapsible
    
  >
    <a-menu
      v-model:selectedKeys="selectedKeys2"
      v-model:openKeys="openKeys"
      mode="inline"
      theme="light"
      :style="{ height: '100%', borderRight: 0 }"
      class="amenucss"
      @click="handleClick"
    >
      <a-menu-item key="/">
        <user-outlined />
        <span> 首页 </span>
      </a-menu-item>
      <a-sub-menu key="/account_manage">
        <template #title>
          <laptop-outlined />
          <span> 广告 </span>
        </template>
        <!-- <div class="menu-my">
          <a-menu-item
            key="/accounts"
            class="sub2-item"
            style="height: 134px !important"
          >
            <div
              style="
                width: 200px !important;
                height: 100px !important;
                display: flex;
                flex-direction: column;
              "
            >
              <div class="menu-sub">
                <div class="menu-sub-box">
                  <div class="menu-title">广告账户</div>
                  <div class="menu-items">
                    <div>免费开户</div>
                    <div @click="router.push('/accounts')">账户列表</div>
                  </div>
                </div>
              </div>
            </div>
          </a-menu-item>
        </div> -->
        <a-menu-item key="/accounts">账户列表</a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="/order_manage">
        <template #title>
          <laptop-outlined />
          <span> 财务 </span>
        </template>
        <a-menu-item key="/orders">交易记录</a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>
<script lang="ts" setup>
import { Options, Vue } from "vue-class-component";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from "@ant-design/icons-vue";
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const selectedKeys2 = ref<string[]>(["/"]);
const openKeys = ref<string[]>([""]);
// export default class Siderbar extends Vue {
//   msg!: string;
// }
const myClick = () => {
  console.log("dianji ");
};
onMounted(() => {
  console.log(route);
  selectedKeys2.value = [route.path];
});
const handleClick = (e: any) => {
  console.log(e);
  router.push(e.key);
};
</script>
<style scoped lang="less">
.menu-sub {
  display: flex;
  .menu-sub-box {
    .menu-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;
    }
    .menu-items {
      display: flex;
      flex-direction: column;
      div {
        text-align: left;
        width: 100px;
        height: 30px;
        line-height: 20px;
        background-color: #f7f7f7;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 10px;
        color: #333;
        &:hover {
          background-color: #fff0e3 !important;
        }
      }
    }
  }
}
.amenucss {
  &:deep(.ant-menu-title-content) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    color: #333;
    span {
      opacity: 1 !important;
      margin-inline-start: 2px;
    }
  }
  &:deep(.ant-menu-item) {
    height: 80px;
    line-height: 10px;
    padding-top: 10px;
  }
  &:deep(.ant-menu-title-content) {
    height: 80px;
    line-height: 10px;
    display: flex;
  }
  &:deep(.ant-menu-submenu) {
    height: 80px;
    line-height: 10px;
  }
  &:deep(.ant-menu-submenu-title) {
    height: 80px;
    padding-top: 10px;
  }
}
.sub2-item {
//   background-color: #fff;
//   &:hover {
//     background-color: #fff !important;
//   }
}
.menu-my {
  &:deep(.ant-menu-item:not(.ant-menu-item-selected:hover)) {
    background-color: white !important;
  }
  &:deep(.ant-menu-light) {
    background-color: white !important;
    color: #333 !important;
  }
  &:deep(.ant-menu-item-selected) {
    background-color: white !important;
    color: #333 !important;
  }
}
</style>
