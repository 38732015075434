<template>
  <icon>
    <template #component>
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        :width="iconw"
        :height="iconh"
      >
        <path
          d="M0 0m192 0l640 0q192 0 192 192l0 640q0 192-192 192l-640 0q-192 0-192-192l0-640q0-192 192-192Z"
          fill="#000000"
        ></path>
        <path
          d="M444.608 416.256V384.64a200.512 200.512 0 0 0-32.512-2.56 241.536 241.536 0 0 0-241.408 241.152c0 82.112 41.088 153.92 103.552 197.568a239.616 239.616 0 0 1-65.024-164.224C208.32 525.76 313.6 418.816 444.608 416.256z"
          fill="#00F2EA"
        ></path>
        <path
          d="M450.56 767.744c59.136 0 107.904-47.04 110.464-106.048V135.744h95.872c-1.664-11.136-3.392-22.208-3.392-33.344H522.496v525.952a110.4 110.4 0 0 1-110.4 106.048c-18.88 0-35.968-4.224-51.392-12.8 20.544 28.16 53.12 46.144 89.92 46.144z"
          fill="#00F2EA"
        ></path>
        <path
          d="M736.512 255.488a179.84 179.84 0 0 1-44.48-119.68h-35.136a183.296 183.296 0 0 0 79.616 119.68zM412.096 513.792c-60.8 0-110.464 49.6-110.464 110.272 0 42.816 24 79.552 59.072 97.536-12.8-17.92-20.48-40.192-20.48-64.128 0-60.8 49.6-110.336 110.4-110.336 11.072 0 22.208 1.664 32.512 5.12V417.92a200.576 200.576 0 0 0-32.512-2.56h-6.016v102.592a135.616 135.616 0 0 0-32.512-4.224z"
          fill="#FF004F"
        ></path>
        <path
          d="M836.672 314.496v101.76a305.664 305.664 0 0 1-182.336-59.008v266.88a241.536 241.536 0 0 1-380.096 197.504 243.008 243.008 0 0 0 177.216 76.992 241.536 241.536 0 0 0 241.408-241.152v-266.88a313.6 313.6 0 0 0 182.4 59.008V317.888c-13.76 0-26.56-0.832-38.592-3.392z"
          fill="#FF004F"
        ></path>
        <path
          d="M653.44 624.128v-266.88a313.536 313.536 0 0 0 182.4 59.008v-101.76a185.152 185.152 0 0 1-99.328-59.008 182.4 182.4 0 0 1-80.448-119.68H561.024v525.952a110.4 110.4 0 0 1-110.4 105.984 110.72 110.72 0 0 1-89.92-46.144 111.04 111.04 0 0 1-59.072-97.472c0-60.8 49.664-110.336 110.464-110.336 11.136 0 22.208 1.664 32.512 5.12V416.256a241.088 241.088 0 0 0-236.288 241.216c0 63.296 24.832 121.408 65.088 164.16a242.688 242.688 0 0 0 138.688 43.648 241.024 241.024 0 0 0 241.408-241.152z"
          fill="#FFFFFF"
        ></path>
      </svg>
    </template>
  </icon>
</template>

<script>
import Icon from "@ant-design/icons-vue";
import { ref } from "vue";
export default {
  components: {
    Icon,
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "18",
    },
    height: {
      type: String,
      default: "18",
    },
  },
  setup(props) {
    const fillColor = ref(props.color);
    const iconw = ref(props.width);
    const iconh = ref(props.height);
    return {
      fillColor,
      iconw,
      iconh,
    };
  },
};
</script>

<style></style>
