<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#f60',
      },
    }"
  >
  </a-config-provider>
  <div class="app">
    <router-view />
  </div>
</template>

<style>
.app {
  /* height: 100vh; */
  /* overflow: hidden; */
}
html {
  overflow: hidden;
}
ol,
ul {
  list-style: none;
}
li,
div,
span,
i {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-break: break-all;
}
</style>
